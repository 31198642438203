import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import SVGButton from '../components/SVG/SVGButton'
import DiscoverMore from '../components/DiscoverMore'
import Layout from '../components/Layout'
import Breadcrumb from '../components/Breadcrumb'
import Banner from '../components/Banner'
import Highlight from '../components/Highlight'
import Container from '../components/Container'
import Section from '../components/Section'
import SectionWithAside from '../components/SectionWithAside'

export const InPracticePage4Template = ({
  bannerimage,
  title,
  theme,
  sectionwithaside,
  secondsection,
  mediasection,
  thirdsection,
  discovermore,
}) => (
  <>
    <Banner bannerImage={bannerimage} title={title} theme={theme} />
    <Breadcrumb to="in-practice" label="In Practice" />
    <Container className="flex pb-10 md:pb-0 pt-12 sm:pt-16 md:pt-20">
      <SectionWithAside content={sectionwithaside} />
    </Container>
    <Container className="flex py-8 sm:py-10 md:py-12">
      <Section content={secondsection} />
    </Container>
    <Container className="mb-10 sm:mb-12 md:mb-16">
      <Highlight
        highlight={mediasection}
        backgroundColor="white"
        textColor="purple"
      />
    </Container>
    <Container outerClassName="py-16 bg-teal">
      <Section
        titleStyle="text-white"
        bodyStyle="text-white"
        content={thirdsection}
      />
    </Container>
    <Container
      outerClassName="bg-black-discovermore text-center"
      className="pb-2 py-12 sm:py-16"
    >
      <DiscoverMore content={discovermore} />
    </Container>
  </>
)

InPracticePage4Template.propTypes = {
  bannerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  theme: PropTypes.string,
  sectionwithaside: PropTypes.object,
  secondsection: PropTypes.object,
  mediasection: PropTypes.object,
  thirdsection: PropTypes.object,
  discovermore: PropTypes.object,
}

const InPracticePage4 = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <InPracticePage4Template
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        theme={frontmatter.theme}
        sectionwithaside={frontmatter.sectionwithaside}
        secondsection={frontmatter.secondsection}
        mediasection={frontmatter.mediasection}
        thirdsection={frontmatter.thirdsection}
        discovermore={frontmatter.discovermore}
      />
    </Layout>
  )
}

InPracticePage4.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default InPracticePage4

export const pageQuery = graphql`
  query InPracticePage4Template($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        theme
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionwithaside {
          title
          body
          asidetitle
          asidebody
        }
        secondsection {
          title
          body
        }
        mediasection {
          highlightsection {
            type
            videoid
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slide {
              caption
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        thirdsection {
          title
          body
        }
        discovermore {
          title
          body
          link
          linklabel
        }
      }
    }
  }
`
